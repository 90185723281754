var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"float_right"},[_c('p',[_c('router-link',{staticClass:"link",attrs:{"to":"/service"}},[_vm._v("联系客服")])],1),_c('p',[(_vm.is_open_refund)?_c('router-link',{staticClass:"link",attrs:{"to":"/refund"}},[_vm._v("极速退款")]):_vm._e()],1)]),_c('div',{staticClass:"recharge"},[_vm._m(0),_c('div',{staticClass:"mobile"},[_c('van-field',{ref:"phone",attrs:{"type":"tel","border":false,"placeholder":"请输入充值手机号码"},model:{value:(_vm.json.phone),callback:function ($$v) {_vm.$set(_vm.json, "phone", $$v)},expression:"json.phone"}})],1),_c('div',{staticClass:"paybtn",on:{"click":_vm.onsubmit}}),_c('div',{staticClass:"tips"},[_c('span',[_c('van-checkbox',{attrs:{"icon-size":"16px"},model:{value:(_vm.isread),callback:function ($$v) {_vm.isread=$$v},expression:"isread"}},[_vm._v("提交即您已阅读并同意")])],1),_c('a',{staticClass:"font-color-blue",on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.privacy_policy
          _vm.contentHtmlModal = true
        }}},[_vm._v("《隐私协议》")]),_c('a',{staticClass:"font-color-blue",on:{"click":()=>{
          _vm.contentHtml = _vm.textInfo.user_agreement
          _vm.contentHtmlModal = true
        }}},[_vm._v("《用户协议》")])]),_c('div',{staticClass:"box"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://ntyz.oss-cn-shanghai.aliyuncs.com/image/zdd/143e31c538d5efdf14ad3aa6f21bdea6.jpg","alt":""}}),_c('div',{staticClass:"font-color-gray t-c",staticStyle:{"position":"absolute","/* bottom":"0","*/width":"90%","top":"2080px","background-color":"#fff","margin":"0 auto","left":"10vw"}},[_c('p',[_vm._v(_vm._s(_vm.textInfo.company_name))]),_c('p',[_vm._v("客服电话：4006178158")]),_c('p',[_vm._v("网站备案号/许可证号:苏ICP备2021030249号-1")])])])]),(_vm.contentHtmlModal)?_c('van-popup',{staticClass:"contentPop",attrs:{"overlay-style":{backgroundColor:'rgba(0,0,0,.5)'},"close-on-click-overlay":false},model:{value:(_vm.contentHtmlModal),callback:function ($$v) {_vm.contentHtmlModal=$$v},expression:"contentHtmlModal"}},[_c('div',{staticClass:"html",domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('div',{staticClass:"close"},[_c('van-button',{staticStyle:{"width":"60%"},attrs:{"type":"primary","round":"","color":"#2d8cf0"},on:{"click":()=>{_vm.contentHtmlModal = false}}},[_vm._v("确定")])],1)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":"https://ntyz.oss-cn-shanghai.aliyuncs.com/image/zdd/298f4171cafe8c74b99bcb971278f167.jpg","alt":""}})])
}]

export { render, staticRenderFns }